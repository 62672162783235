@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Roboto", sans-serif;
}

/* start loading css */
.animationload {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
.osahanloading {
  animation: 1.5s linear 0s normal none infinite running osahanloading;
  background: #fed37f none repeat scroll 0 0;
  border-radius: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 50px;
}
.osahanloading::after {
  animation: 1.5s linear 0s normal none infinite running osahanloading_after;
  border-color: #85d6de transparent;
  border-radius: 80px;
  border-style: solid;
  border-width: 10px;
  content: "";
  height: 80px;
  left: -15px;
  position: absolute;
  top: -15px;
  width: 80px;
}
@keyframes osahanloading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    background: #85d6de none repeat scroll 0 0;
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* close loading css */

@tailwind base;
@tailwind components;
@tailwind utilities;
